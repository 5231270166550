<template>
  <div class="graffiti">
    <header>
      <div>
        <img
          @click="$router.go(-1)"
          src="@assets/back.png"
          class="back-button"
        />
        <img src="@assets/graffiti/header.png" />
      </div>
    </header>

    <div class="uk-container">
      <div class="uk-child-width-1-2 uk-text-center" uk-grid="masonry: true">
                                               <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-7.2.jpeg')"
          />
        </div>
                                       <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-7.1.jpeg')"
          />
        </div>
                               <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-8.2.jpeg')"
          />
        </div>
                       <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-8.1.jpeg')"
          />
        </div>
                <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-6.1.jpeg')"
          />
        </div>
                <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-6.2.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-1.1.jpg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-1.2.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-2.1.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-2.2.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-3.1.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-3.2.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-4.1.jpeg')"
          />
        </div>
        <div>
          <img
            uk-img
            :data-src="require('@assets/graffiti/graffiti-4.2.jpeg')"
          />
        </div>
        <div>
          <img uk-img :data-src="require('@assets/graffiti/graffiti-5.jpeg')" />
        </div>
      </div>
      <div class="films-2">
     
      <h3 class="uk-text-center">
      </h3>
<div class="uk-container">
        <div
          class="uk-grid-small uk-grid uk-child-width-1-3@m uk-child-width-1-2@s"
          uk-grid
        >
          </div>
      </div>
    </div>
    </div>

    <footer class="uk-padding-large">
      <div>
        <img
          @click="$router.go(-1)"
          src="@assets/back.png"
          class="back-button"
        />
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.graffiti {
  background: rgb(134, 37, 37);
  min-height: 100vh;
}

.header {
  width: 30rem;
}
</style>
